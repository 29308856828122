import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Get Faded Barbershop",
  pageDescription:
    "A creative barbershop sells everything from fresh fades to custom t-shirts with one intuitive system.",
  title: "Fresh cuts and faster sales",
  subTitle: `How barber and business owner Jose Moya sells products and services with ease thanks to SpotOn Retail.`,
  businessName: "Get Faded Barbershop",
  industry: "Retail, barbershop",
  location: "Santa Cruz, CA",
  products: [spotonProducts.RETAIL],
  description:
    "Get Faded Barbershop is so much more than a hip place to get a haircut. Owner Jose Moya sells custom merchandise, hair products, and even collaborates with a local brewery to make a beer. When he’s not creating, Moya can check in on how his products and services are selling on the SpotOn Dashboard and find accurate data in one place.",
  quoteData: {
    imgName: "get-faded-barbershop.png",
    quote: `“SpotOn has a lot of cool features that help business owners out. I like the fact that you can add pictures to the catalog, so people working the register know exactly which item to click on, and you can sell gift cards too. Plus, everything’s in one place.”`,
    personName: "Jose Moya",
    personTitle: "Owner, Get Faded Barbershop",
  },
  goal: `When Moya opened his own barbershop, he quickly expanded from cutting hair to selling unique products and growing a team of talented barbers. He needed one platform to sell both products and services that his new employees could pick up easily.`,
  solution: `With SpotOn Retail, Get Faded doesn’t have to switch between platforms to do business. All their data is accurate and streamlined, accessible from anywhere via the SpotOn Dashboard. The check-out process is faster thanks to SpotOn Retail’s intuitive interface and the ability to add product images. And with dual pricing, Moya can save on processing fees that can go toward their expansion.`,
  solutionReadMore: {
    text: `Read more about their story.`,
    url: `https://www.spoton.com/blog/get-faded-barbershop/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `3-5 minute`, description: `Faster checkout` },
      {
        title: `$6,000`,
        description: `Savings in yearly credit card processing fees`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Financing and payment processing for an automotive shop",
    imageName: "24hr-diesel-repair.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-08-12",
    href: "/case-studies/24hr-diesel-repair",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Growing a flower business from the ground up",
    imageName: "flowers-on-phoenix.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-28",
    href: "/case-studies/flowers-on-phoenix",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
    ],
  },
  {
    title: "A blooming nursery gives customers a faster experience",
    imageName: "unique-plants-and-palms.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-13",
    href: "/case-studies/unique-plants-and-palms",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
